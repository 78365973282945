<template>
	<div class="index" :style="{ backgroundImage: showNav ? 'url(\'https://69csgoskins.oss-cn-beijing.aliyuncs.com/background/pc--.png\')' : 'url(\'https://69csgoskins.oss-cn-beijing.aliyuncs.com/background/h5--.png\')' }">
		<CenterNav v-if="!showNav" />
		<div class="center" :class="{ yidongcenter: !showNav }" >
			<!-- <div class="center-Content" v-if="!showNav">
			<div class="first" v-for="(item,index) in centerContent" @click="bindNav(item.type,item.url)">
				<div><img :src="item.image" alt=""></div>
				<div>{{item.name}}</div>
			</div>
		</div> -->
			<div  class="box-area">
				<div class="box">
					<ul>
						<li v-for="(v, i) in typeBoxList" :key="i"  class="box-hover">
							<div @click="openbox(v.id)">
								<div class="bei1">
									<img :src="v.img_main" />
								</div>
								<div class="bei2">
									<img v-if="v.img_active" :src="v.img_active" />
								</div>
								<div class="name">
									<div class="name-warp">
										<span>{{ v.name }}</span>
									</div>
								</div>
							</div>
						</li>
						<dir style="clear: both"></dir>
					</ul>
				</div>
				<div class="clear"></div>
			</div>
		</div>
		<myhomebot></myhomebot>
		<navheader :showNav="showNav" :yidongmenu="yidongmenu" :openHongbao="openHongbao"></navheader>
		<!-- <div id="mask" ref="mask" v-if="adshow && showNav">
			<img :src="adshowimg" class="mask_img" @click="adClick()" />
		</div> -->
	</div>
</template>

<script>
	import Utils from "./../assets/js/util.js";
	import myslide from "@/components/my_slide1.vue";
	import myhomebot from "@/components/my_homebot.vue";
	import myinform from "@/components/my_inform.vue";
	import navheader from "@/components/navheader.vue";
	import {
		Swiper,
		SwiperSlide,
		directive
	} from "vue-awesome-swiper";
	import "swiper/swiper-bundle.css";
	import Swiper2, {
		Navigation,
		Pagination,
		Autoplay
	} from "swiper";
	import {
		CenterNav
	} from "@/views/common";

	Swiper2.use([Navigation, Pagination, Autoplay]);
	export default {
		components: {
			myslide,
			myhomebot,
			myinform,
			Swiper,
			SwiperSlide,
			navheader,
			CenterNav,
		},
		directives: {
			swiper: directive,
		},
		props: ["yidongmenu", "showNav", "openHongbao"],
		data() {
			return {
				centerContent: [{
						name: "商城",
						image: require("../assets/img/nav2/b55.png"),
						url: "/Roll",
						type: 1,
					},
					{
						name: "roll房",
						image: require("../assets/img/nav2/b11.png"),
						url: "/Arena",
						type: 1,
					},
					{
						name: "CD-KEY",
						image: require("../assets/img/cdk.png"),
						url: "/Payment?type=cdk",
						type: 1,
					},
					{
						name: "客服",
						image: require("../assets/img/kefu.png"),
						type: 2,
					},
					{
						name: "取回助手",
						image: require("../assets/img/back.svg"),
						type: 3,
					},
				],
				typeBoxList: [],
				listSlide: [],
				img: "",
				img1: require("../assets/img/1mdpi.png"),
				active: [],
				windowWidth: document.body.clientWidth,
				userState: "",
				adshow: false,
				adshowimg: require("../assets/img/adshow.png"),
			};
		},
		methods: {
			bindNav(type, url) {
				if (type == 2) {
					window.open(
						"https://qm.qq.com/cgi-bin/qm/qr?k=s7qihLq_4vvaIaOwNlsGQDq6e3wSxdzM&jump_from=webapi"
					);
				}
				if (type == 1) {
					this.$router.push({
						path: url
					});
				}
				if (type == 3) {
					this.$emit("backAction", true);
				}
			},
			adClick() {
				this.adshow = false;
				this.$store.commit("getLogin", true);
			},
			//请求背景图片
			getBack() {
				let _this = this;
				_this.$axios.post("/index/Setting/background").then((res) => {
					if (res.data.status == 1) {
						this.img = res.data.data.img;
						if (!this.img) {
							this.img = this.img1;
						}
					}
				});
			},
			// 首页活动模块
			getboxList() {
				let _this = this;
				_this.$axios.post("/index/Box/getNewList").then((res) => {
					if (res.data.status == 1) {
						_this.typeBoxList = res.data.data;
                        console.log("12121")
						console.log(_this.typeBoxList);
					}
				});
			},
			openbox(box_id) {
				this.$router.push({
					path: `/Openbox`,
					query: {
						box_id: box_id,
                        is_activity: 1,
					},
				});
			},
			getPlayerInfo(pid) {
				this.$axios.post("/index/User/getPlayerInfo", {}).then((res) => {
					if (res.data.data.status == 1) {
						localStorage.setItem("userInfo", JSON.stringify(res.data.data));
						Utils.$emit("login", true);
						that.selfLogin();
						return
					}
				});
			},
			selfLogin() {
				let userInfo = JSON.parse(localStorage.getItem("userInfo"));
				if (!userInfo) {
					return
				}
				this.me = userInfo;
				this.money = userInfo.total_amount;
				this.masonry = userInfo.masonry;
				this.loginfalse = false;
				this.regHint = false;
			},
		},
		activated() {
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			if (!userInfo) {
				this.$parent.nextLogin();
			}
		},
		mounted() {
			if (this.$route.query.pid > 0) {
				this.z(this.$route.query.pid);
			}
			this.getboxList();
			this.getBack();
			let userInfo = JSON.parse(localStorage.getItem("userInfo"));
			if (!userInfo) {
				this.adshow = true;
				return
			}
			this.userState = userInfo.state;
			// this.$emit('backAction','click')
		},
		computed: {
			swiper() {
				return this.$refs.mySwiper.$swiper;
			},
		},
	};
</script>

<style lang="less" scoped>
	.swiper-box {
		overflow: hidden;
		border-radius: 8px !important;
		padding: 8px;
		margin-top: 20px;
	}

	.index {
		position: relative;
		overflow: hidden;
		overflow-y: auto;
		width: 100%;
		height: 100%;
		background-size: 100% 100%;
		background-color: rgba(0, 0, 0, 1);

		//--------
		// position:absolute;
		// top:0;
		// left:0;
		// height:calc(100vh);
		width: 100%;
		background-position: center 0;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-size: cover;
		-webkit-background-size: cover;
		/* 兼容Webkit内核浏览器如Chrome和Safari */
		-o-background-size: cover;
		/* 兼容Opera */
		zoom: 1;

		/* .top::-webkit-scrollbar {
        display: none;
  }*/
		// ::after {
		//   position: absolute;
		//   top: 0;
		//   left: 0;
		//   content: "";
		//   background-color: yellow;
		//   opacity: 0.2;
		//   z-index: 1;
		//   width: 100%;
		//   height: 100%;
		// }

		.yidongcenter {
			// background: url("../assets/img/newback.png");
			// background-size: 400% !important;
			// background-position: -410px -100px !important;
		}

		.center {
			background-size: 100%;
			min-height: 100%;
			padding-bottom: 40px;
			padding-left: 4%;
			padding-right: 4%;
			padding-top: 36px;
			margin-top: -5px;
			background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

			.box-area {
				margin-bottom: 70px;
			}

			.box {
				.title {
					margin-top: 0px;
					display: flex;
					justify-content: center;
					font-size: 26px;
					margin-bottom: 0px;
					height: 75px;
					color: #ffffff;
					// padding-top: 36px;
					background: url("../assets/img/index/37e75054-b354-44ba-9b2d-58f4d46199b5.webp") no-repeat top center;
					position: relative;

					span {
						// display: table;
						margin-top: -15px;
						// border-bottom: 3px solid #fff;
					}
				}

				ul {
					padding: 0 4%;
					display: flex;
					flex-wrap: wrap;

					li {
						width: 19%;
						position: relative;

						.bei1 {
							// display: flex;
							justify-content: center;

							// height: 75.7%;
							img {
								width: 100%;
								// height: 300px;
							}
						}

						.bei2 {
							width: 100%;
							display: flex;
							justify-content: center;
							position: absolute;
							top: 20%;

							img {
								width: 60%;
								height: 60%;
							}
						}

						.name {
							display: flex;
							justify-content: center;

							.name-warp {
								display: flex;
								align-items: center;
								// color: #c3c3e2;
								color: #ffffff;
								font-size: 18px;

								// font-weight: 600;
								img {
									width: 25px;
									height: 25px;
									margin-right: 10px;
								}
							}
						}

						.money {
							margin-top: 15px;
							display: flex;
							justify-content: center;

							.money-warp {
								position: relative;
								display: flex;
								align-items: center;
								justify-content: space-between;
								color: #fff;
								border: 2px solid #ff9b0b;
								// background-color: #8dc0dd;
								// background:linear-gradient(to right, #FF571B 0%, #CE34C1 100%) border-box;
								overflow: hidden;
								border-radius: 20px;
								padding: 6px 16px;
								font-size: 14px;

								//boder颜色渐变
								// border: 2px transparent solid;
								// background-clip: padding-box, border-box;
								// background-origin: padding-box, border-box;
								// background-image: linear-gradient(135deg,rgba(25,30,46,0.8),rgba(255, 255, 255, 0.5)), linear-gradient(135deg, #ff571b, #ff9b0b);
								// background-color:transparent;

								img {
									width: auto;
									height: 20px;
									margin-right: 5px;
								}
							}
						}
					}
				}

				//动画
				.money-buy {
					display: none;
				}

				.money-kong {
					display: none;
				}

				.box-hover:hover {
					cursor: pointer;
				}

				.box-hover:hover .money-buy {
					display: block;
					position: absolute;
					right: -1px;
					// background-color: #FF9B0B;
					background-image: linear-gradient(to right, #ff571b, #ff9b0b);
					border-radius: 20px;
					color: white;
					padding: 7px 16px;
				}

				.box-hover:hover .money-kong {
					display: block;
					margin-left: 60px;
				}

				.box-hover:hover .bei2 {
					position: absolute;
					top: 20%;
					animation: boxhover 1.5s linear 0s infinite alternate;
				}

				@keyframes boxhover {
					0% {
						top: 20%;
					}

					50% {
						top: 30%;
					}

					100% {
						top: 20%;
					}
				}
			}
		}
	}

	.center-Content {
		display: flex;
		align-content: center;
		color: hsla(0, 0%, 100%, 0.5);
		margin: 0 -12px;
		justify-content: space-between;

		.first {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100px;
		}

		img {
			width: 25px;
		}
	}

	.active {
		// width: 40%;
		margin: 20px auto;
		position: relative;

		// display: flex;
		// float: left;
		.active-img {
			width: calc(33.33333% - 5px);
			position: relative;
			margin-left: 2.5px;
			margin-right: 2.5px;
			float: left;

			:hover {
				// transform: translate3d(0,-10px,0);
				// transition: transform 1s;
				animation: imghover 1s infinite;
				animation-iteration-count: 1;
				animation-fill-mode: forwards;
			}
		}

		.img-1 {
			position: absolute;
			cursor: pointer;
		}

		.img-2 {
			visibility: hidden;
		}

		.img-1,
		.img-2 {
			// width: inherit;
			width: 100%;
			height: 90px;
			object-fit: cover;
		}

		@keyframes imghover {
			from {
				top: 0px;
			}

			to {
				top: -15px;
			}
		}
	}

	#mask {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 999;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.swiper-more {
		width: 80%;
		height: auto;
		img {
			border-radius: 8px !important;
		}
	}

	@media screen and (max-width: 768px) {
		.swiper-more {
			width: 100%;
		}

	}
	.mask_img {
		width: 50%;
	}

	@media screen and (max-width: 768px) {
		.active {
			width: 98%;
		}

		.mask_img {
			width: 100%;
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1024px) {
		.active-img {
			width: calc(50% - 5px) !important;
		}
	}

	@media screen and (max-width: 567px) {
		.active-img {
			width: 100% !important;
		}

		.box-area {
			margin-bottom: 50px !important;
		}

		.title {
			font-size: 14.6px !important;
			height: 40px !important;
			margin: 0px 0 !important;
			background: url("../assets/img/index/title.b1b39dba.png") no-repeat top center !important;
			background-size: contain !important;

			span {
				margin-top: -8px !important;
				font-weight: bold;
			}
		}
	}
</style>